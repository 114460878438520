import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import AppLoader from '@crema/components/AppLoader';

const PortfolioListing = React.lazy(
  () => import('../../../modules/dashboards/Projects'),
);
const PortfolioDetails = React.lazy(
  () => import('../../../modules/dashboards/Projects/Details'),
);
const CreatePortfolio = React.lazy(
  () => import('../../../modules/dashboards/CreateProject'),
);

const EditPortfolio = React.lazy(
  () => import('../../../modules/dashboards/Projects/EditPortfolio'),
);
const OurPartners = React.lazy(
  () => import('../../../modules/dashboards/Projects/OurPartners'),
);

const AllCategories = React.lazy(
  () => import('../../../modules/dashboards/AllCategories'),
);

const Blog = React.lazy(() => import('../../../modules/dashboards/Blog'));
const BlogDetail = React.lazy(
  () => import('../../../modules/dashboards/Blog/BlogDetail'),
);
const BlogCreate = React.lazy(
  () => import('../../../modules/dashboards/Blog/CreateBlog'),
);
const BlogEditPage = React.lazy(
  () => import('../../../modules/dashboards/Blog/EditBlog'),
);

const SuspenseFallback = () => <AppLoader />;

export const dashBoardConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/portfolios',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <PortfolioListing />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/portfolio/details/:id',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <PortfolioDetails />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/ourpartners',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <OurPartners />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/categories', '/categories/:slug'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <AllCategories />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/createPortfolio',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <CreatePortfolio />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/updatePortfolio/:id',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <EditPortfolio />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/blogdetails/:id',
    element: <BlogDetail />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/bloglisting',
    element: <Blog />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/createBlog',
    element: <BlogCreate />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/updateBlog/:id',
    element: <BlogEditPage />,
  },
];
