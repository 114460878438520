import {InstallationDataType} from '@crema/types/models/extrapages/Portfolio';

export const installationData: InstallationDataType[] = [
  {
    id: 2001,
    ques: 'Approximately how many people work in your company?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2002,
    ques: 'What are some common system requirement to install your software?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2003,
    ques: 'What are some pre-requisites before installing your software?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2004,
    ques: 'Is there any document available for instructions regarding to installation of software?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2005,
    ques: 'What are some common system requirement to install your software?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
];
