import React, {useState} from 'react';
import {useAuthUser} from '@crema/hooks/AuthHooks';
import {Formik} from 'formik';
import {object, string} from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import {Box} from '@mui/material';
import {useAppDispatch} from 'toolkit/hooks';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  SHOW_MESSAGE,
  SHOW_ERROR_MESSAGE,
  FETCH_SUCCESS,
  FETCH_START,
  FETCH_ERROR,
} from '@crema/types/actions/Common.action';
import AppInfoView from '@crema/components/AppInfoView';

const validationSchema = object({
  name: string()
    .required('name is required')
    .min(3, 'name should be at least 3 characters'),
  description: string().required('description is required'),
});

const PersonalInfo = () => {
  const {user} = useAuthUser();
  const dispatch = useAppDispatch();

  const [mediaID, setMediaID] = useState<string>('');

  const handleUpdateProfile = async (data: any) => {
    const updatedBody = {
      profileID: mediaID,
      name: data.name,
      description: data.description,
    };
    try {
      dispatch({type: FETCH_START});
      await jwtAxios.patch('user/updateProfile', updatedBody);
      dispatch({type: FETCH_SUCCESS});
      dispatch({
        type: SHOW_MESSAGE,
        payload: 'Profile update successfull!',
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.response.data.message});
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: 'Something Went Wrong',
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          maxWidth: 650,
        }}
      >
        <Formik
          validateOnBlur={true}
          validateOnChange={true}
          validateOnMount={true}
          enableReinitialize={false}
          initialValues={{
            ...user,
            profileImage: user?.profileImage ? user?.profileImage : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data, {setSubmitting}) => {
            setSubmitting(true);
            handleUpdateProfile(data);
            setSubmitting(false);
          }}
        >
          {({isSubmitting, values, setFieldValue}) => {
            return (
              <PersonalInfoForm
                values={values}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                setMediaID={setMediaID}
                user={user}
              />
            );
          }}
        </Formik>
      </Box>
      <AppInfoView />
    </>
  );
};

export default PersonalInfo;
