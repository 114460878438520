import React, {ReactNode} from 'react';
// import FirebaseAuthProvider from '@crema/services/auth/firebase/FirebaseAuthProvider';
// import {useDispatch} from 'react-redux';
// import {
//   FETCH_START,
//   FETCH_ERROR,
//   FETCH_SUCCESS,
// } from '@crema/constants/ActionTypes';
import JWTAuthAuthProvider from '@crema/services/auth/jwt-auth/JWTAuthProvider';

type Props = {
  children: ReactNode;
};

// const AppAuthProvider = ({children}: Props) => {
//   const dispatch = useDispatch();

//   const fetchStart = () => {
//     dispatch({type: FETCH_START});
//   };
//   const fetchError = (message: string) => {
//     dispatch({type: FETCH_ERROR, payload: message});
//   };
//   const fetchSuccess = () => {
//     dispatch({type: FETCH_SUCCESS});
//   };

//   return (
//     <FirebaseAuthProvider
//       fetchStart={fetchStart}
//       fetchError={fetchError}
//       fetchSuccess={fetchSuccess}
//     >
//       {children}
//     </FirebaseAuthProvider>
//   );
// };

const AppAuthProvider = ({children}: Props) => {
  return <JWTAuthAuthProvider>{children}</JWTAuthAuthProvider>;
};

export default AppAuthProvider;
