import {CryptoPropsType} from '@crema/types/models/dashboards/Crypto';

const cryptoData: CryptoPropsType = {
  coinsData: {
    bitcoin: {
      price: '7289.75',
      increment: 0.8,
    },
    etherium: {
      price: '170.720',
      increment: 0.76,
    },
    liteCoin: {
      price: '65.1200',
      increment: -0.4,
    },
    ripple: {
      price: '0.2544',
      increment: 0.08,
    },
  },
  popularCoins: [
    {
      id: 1,
      name: 'Bitcoin',
      shortName: 'BTC',
      marketCap: '129,820,932',
      volume: '25,111,773',
      h: '2.50',
      image: '/assets/images/bitcoin.svg',
      color: '#fb8c00',
    },
    {
      id: 2,
      name: 'Ethereum',
      shortName: 'ETH',
      marketCap: '24,909,820',
      volume: '12,344,434',
      h: '0.45',
      image: '/assets/images/etherium.svg',
      color: 'black',
    },
    {
      id: 3,
      name: 'Litecoin',
      shortName: 'LTC',
      marketCap: '137,334,223',
      volume: '43,434,213',
      h: '3.43',
      image: '/assets/images/litcoin.svg',
      color: '#42a5f5',
    },
    {
      id: 4,
      name: 'Monero',
      shortName: 'XMR',
      marketCap: '21,445,237',
      volume: '32,324,655',
      h: '0.93',
      image: '/assets/images/bitcoin.svg',
      color: '#ff9800',
    },
    {
      id: 5,
      name: 'Dashcoin',
      shortName: 'DASH',
      marketCap: '124,674,765',
      volume: '94,342,323',
      h: '.30',
      image: '/assets/images/bitcoin.svg',
      color: '#1e88e5',
    },
  ],
  totalBalanceData: {
    balance: '33692.00',
    coins: [
      {
        id: 32423,
        name: 'Bitcoin',
        value: 9.654,
      },
      {
        id: 3333,
        name: 'Monero',
        value: 76.184,
      },
      {
        id: 44334,
        name: 'Ripple',
        value: 1567.5,
      },
      {
        id: 54323,
        name: 'Litecoin',
        value: 56.78,
      },
    ],
  },
  stories: [
    {
      id: 1,
      srcImg: '/assets/images/dashboard/stories1.jpg',
      title: 'Cryptocurrency prices today on September 18: Ethereum falls 2%',
      tag: 'Forbes',
      time: '2 min ago',
    },
    {
      id: 2,
      srcImg: '/assets/images/dashboard/stories2.png',
      title:
        'Katy Perry, Nas, Jason Derulo invest big in crypto music platform Audius',
      tag: 'Moneycontrol',
      time: '1 hour ago',
    },
    {
      id: 3,
      srcImg: '/assets/images/dashboard/stories3.png',
      title: 'Major stories on Bitcoin, Coinswitch Kuber, etc',
      tag: 'Forbes',
      time: '2 hours ago',
    },
    {
      id: 4,
      srcImg: '/assets/images/dashboard/stories4.png',
      title: 'Cryptocurrency September 17: Bitcoin, Ethereum trade in the red',
      tag: 'Google',
      time: '2 hours ago',
    },
    {
      id: 5,
      srcImg: '/assets/images/dashboard/stories5.png',
      title: 'Coinswitch Kuber in first India bet at $2 billion valuation',
      tag: 'Forbes',
      time: '2 hours ago',
    },
    {
      id: 6,
      srcImg: '/assets/images/dashboard/stories3.png',
      title: 'Major stories on Bitcoin, Coinswitch Kuber, etc',
      tag: 'Forbes',
      time: '2 hours ago',
    },
    {
      id: 7,
      srcImg: '/assets/images/dashboard/stories4.png',
      title: 'Cryptocurrency September 17: Bitcoin, Ethereum trade in the red',
      tag: 'Google',
      time: '2 hours ago',
    },
    {
      id: 8,
      srcImg: '/assets/images/dashboard/stories5.png',
      title: 'Coinswitch Kuber in first India bet at $2 billion valuation',
      tag: 'Forbes',
      time: '2 hours ago',
    },
  ],
  ordersActivities: [
    {
      id: 1,
      transactionID: 'TXN73547248764',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Buy Bitcoin',
      date: '02/10/2020 11:37 PM',
      usdAmount: '4,565.75',
      amount: '+ 0.2040',
    },
    {
      id: 2,
      transactionID: 'TXN35345435345',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/ethereum-light.svg',
          title: 'Ethereum',
        },
      ],
      description: 'Buy Ethereum',
      date: '02/10/2020 10:37 PM',
      usdAmount: '2,039.39',
      amount: '+ 0.12600',
    },
    {
      id: 3,
      transactionID: 'TXN43534543543',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/up-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Sell Bitcoin',
      date: '02/10/2020 10:45 PM',
      usdAmount: '9,285.71',
      amount: '+ 0.94750',
    },
    {
      id: 4,
      transactionID: 'TXN34545646633',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/up-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/ethereum-light.svg',
          title: 'ethereum',
        },
      ],
      description: 'Sell Ethereum',
      date: '02/11/2020 10:25 PMM',
      usdAmount: '12,596.75',
      amount: '+ 1.02050',
    },
    {
      id: 5,
      transactionID: 'TXN98989897677',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Buy Bitcoin',
      date: '02/10/2020 11:37 PM',
      usdAmount: '400.00',
      amount: '+ 0.00056',
    },
    {
      id: 6,
      transactionID: 'TXN73547248764',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/up-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/ethereum-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Sell Ethereum',
      date: '02/09/2020 05:15 PM',
      usdAmount: '6,246.50',
      amount: '+ 0.02575',
    },
    {
      id: 7,
      transactionID: 'TXN73547248764',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Buy Bitcoin',
      date: '02/10/2020 11:37 PM',
      usdAmount: '4,565.75 USD',
      amount: '+ 0.2040 BTC',
    },
    {
      id: 8,
      transactionID: 'TXN73547248764',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Buy Bitcoin',
      date: '02/10/2020 11:37 PM',
      usdAmount: '4,565.75 USD',
      amount: '+ 0.2040 BTC',
    },
    {
      id: 9,
      transactionID: 'TXN73547248764',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Buy Bitcoin',
      date: '02/10/2020 11:37 PM',
      usdAmount: '4,565.75 USD',
      amount: '+ 0.2040 BTC',
    },
    {
      id: 10,
      transactionID: 'TXN73547248764',
      type: [
        {
          id: 1,
          icon: '/assets/images/dashboard/down-arrow-light.svg',
          title: 'Down',
        },
        {
          id: 2,
          icon: '/assets/images/dashboard/bitcoin-light.svg',
          title: 'Bitcoin',
        },
      ],
      description: 'Buy Bitcoin',
      date: '02/10/2020 11:37 PM',
      usdAmount: '4,565.75 USD',
      amount: '+ 0.2040 BTC',
    },
  ],
  buySell: {
    coinList: [
      {
        id: 1,
        icon: '/assets/images/dashboard/bitcoin-icon.svg',
        name: 'Bitcoin',
        shortName: 'BTC',
        coinColor: '#F04F47',
        usdPrice: 569624,
      },
      {
        id: 2,
        icon: '/assets/images/dashboard/dogecoin-icon.svg',
        name: 'Dogecoin',
        shortName: 'DOGE',
        coinColor: '#1CD1F9',
        usdPrice: 162353,
      },
      {
        id: 3,
        icon: '/assets/images/dashboard/ethereum-icon.svg',
        name: 'Ethereum',
        shortName: 'ETH',
        coinColor: '#1C67F9',
        usdPrice: 345322,
      },
      {
        id: 4,
        icon: '/assets/images/dashboard/litecoin-icon.svg',
        name: 'Litecoin',
        shortName: 'LTC',
        coinColor: '#DA1CF9',
        usdPrice: 234234,
      },
    ],
  },
  gainerLooser: [
    {
      id: 1,
      icon: '/assets/images/dashboard/gainer-looser-1.png',
      name: 'Gravitoken GRV',
      type: 'gainer',
      percentage: '+458.87%',
      amount: '$7,836,170',
    },
    {
      id: 2,
      icon: '/assets/images/dashboard/gainer-looser-2.png',
      name: 'Greenex GNX',
      type: 'looser',
      percentage: '-96.21%',
      amount: '$526,915',
    },
    {
      id: 3,
      icon: '/assets/images/dashboard/gainer-looser-3.png',
      name: 'Blockchain Exchange Alliance BXA',
      type: 'looser',
      percentage: '-74.95%',
      amount: '$77,350',
    },
    {
      id: 5,
      icon: '/assets/images/dashboard/gainer-looser-5.png',
      name: 'Bridge Oracle BRG',
      type: 'gainer',
      percentage: '+183.24%',
      amount: '$23,242,618',
    },
    {
      id: 4,
      icon: '/assets/images/dashboard/gainer-looser-4.png',
      name: 'Block Commerce Protocol BCP',
      type: 'looser',
      percentage: '-90.10%',
      amount: '$601,994',
    },
    {
      id: 6,
      icon: '/assets/images/dashboard/gainer-looser-6.png',
      name: 'Solomon Defi SLM',
      type: 'looser',
      percentage: '-74.95%',
      amount: '$77,350',
    },
    {
      id: 7,
      icon: '/assets/images/dashboard/gainer-looser-7.png',
      name: 'Metaverse Dualchain Network Architecture DNA',
      type: 'gainer',
      percentage: '+136.44%',
      amount: '$191,939',
    },
    {
      id: 8,
      icon: '/assets/images/dashboard/gainer-looser-8.png',
      name: 'Story STORY',
      type: 'gainer',
      percentage: '+105.09%',
      amount: '$83,085',
    },
  ],
  atcStatics: [
    {
      name: 'Jan',
      Buy: 15670,
      Sell: 27456,
    },
    {
      name: 'Feb',
      Buy: 35767,
      Sell: 20123,
    },
    {
      name: 'Mar',
      Buy: 18567,
      Sell: 28546,
    },
    {
      name: 'Apr',
      Buy: 52568,
      Sell: 25123,
    },
    {
      name: 'May',
      Buy: 25898,
      Sell: 40896,
    },
    {
      name: 'Jun',
      Buy: 45234,
      Sell: 25585,
    },
    {
      name: 'Jul',
      Buy: 30785,
      Sell: 40568,
    },
    {
      name: 'Aug',
      Buy: 54568,
      Sell: 24458,
    },
    {
      name: 'Sep',
      Buy: 28980,
      Sell: 50457,
    },
    {
      name: 'Oct',
      Buy: 50344,
      Sell: 27458,
    },
    {
      name: 'Nov',
      Buy: 25089,
      Sell: 54546,
    },
    {
      name: 'Dec',
      Buy: 40780,
      Sell: 37345,
    },
  ],
  cardDetails: {
    cardDetail: [
      {
        id: 1,
        title: 'Card Holder',
        name: 'Demo One',
      },
      {
        id: 2,
        title: 'Bank Name',
        name: 'HDFC First Bank',
      },
      {
        id: 3,
        title: 'Card Number',
        name: '**** **** **** 1234',
      },
      {
        id: 4,
        title: 'Valid Date',
        name: '12/21',
      },
    ],
    monthlyLimit: [
      {
        id: 1,
        value: 66,
        activeColor: '#F04F47',
        title: 'Main Limits',
        income: '$10,000',
      },
      {
        id: 2,
        value: 31,
        activeColor: '#0BBFDB',
        title: 'Seconds',
        income: '$500',
      },
      {
        id: 3,
        value: 17,
        activeColor: '#3D5AFE',
        title: 'Others',
        income: '$100',
      },
    ],
  },
  quickTransfer: {
    recentContact: [
      {
        id: 1,
        image: '/assets/images/avatar/A11.jpg',
        name: 'David Gaskin',
      },
      {
        id: 2,
        image: '/assets/images/avatar/A10.jpg',
        name: 'Jems Parcey',
      },
      {
        id: 3,
        image: '/assets/images/avatar/A8.jpg',
        name: 'Chirag Patel',
      },
      {
        id: 4,
        image: '/assets/images/avatar/A7.jpg',
        name: 'Yami Gautam',
      },
      {
        id: 5,
        image: '/assets/images/avatar/A5.jpg',
        name: 'Petrick Dang',
      },
      {
        id: 6,
        image: '/assets/images/avatar/A2.jpg',
        name: 'Jim Raput',
      },
    ],
    coinList: [
      {
        id: 1,
        icon: '/assets/images/dashboard/bitcoin-icon.svg',
        value: 569624,
        shortName: 'BTC',
      },
      {
        id: 2,
        icon: '/assets/images/dashboard/dogecoin-icon.svg',
        value: 162353,
        shortName: 'DOGE',
      },
      {
        id: 3,
        icon: '/assets/images/dashboard/ethereum-icon.svg',
        value: 345322,
        shortName: 'ETH',
      },
      {
        id: 4,
        icon: '/assets/images/dashboard/litecoin-icon.svg',
        value: 234234,
        shortName: 'LTC',
      },
    ],
  },
};
export default cryptoData;
