/* eslint-disable prettier/prettier */
import {authRole} from '@crema/constants/AppConst';

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Crema User',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.User,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  // console.log(user);
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  // console.log('getUserFromJwtAuth ==>', user);
  if (user)
    return {
      uid: user?._id,
      name: user?.name,
      email: user?.email,
      description: user?.description,
      profileImage: user?.profileImage ? user?.profileImage : 'Admin',
      role: user?.role || authRole.Admin,
      type: user?.type,
      createdAt: user?.createdAt,
    };
  return user;
};
