/* eslint-disable prettier/prettier */
import { createReducer } from '@reduxjs/toolkit';
import {
  GetCategoryList,
  GetCategoryData,
  GetAllCategories,
  SetPortfolioListing,
  setBlogListing,
  setSingleBlog,
  setSingleProject,
} from './ActionTypes/Dashboard';

interface initialStateTypes {
  categoryList: any | null;
  categoryData: any | null;

  allCategories: any | [];

  portfolioListing: any | null | object;
  blogListing: any | object;
  singleBlog: null | any;
  singleProject: null | object | any;
}

const initialState: initialStateTypes = {
  categoryList: [],
  categoryData: null,
  allCategories: [],

  portfolioListing: [],
  blogListing: [],
  singleBlog: null,
  singleProject: null,
};

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GetCategoryList, (state, action) => {
      state.categoryList = action.payload;
    })
    .addCase(GetCategoryData, (state, action) => {
      state.categoryData = action.payload;
    })
    .addCase(GetAllCategories, (state, action) => {
      state.allCategories = action.payload;
    })
    .addCase(SetPortfolioListing, (state: any, action: any) => {
      state.portfolioListing = action.payload;
    })
    .addCase(setBlogListing, (state: any, action: any) => {
      state.blogListing = action.payload;
    })
    .addCase(setSingleBlog, (state: any, action: any) => {
      state.singleBlog = action.payload;
    })
    .addCase(setSingleProject, (state: any, action: any) => {
      state.singleProject = action.payload;
    });
});

export default dashboardReducer;
