import React from 'react';
import AppCard from '@crema/components/AppCard';
import {Box, Avatar, Typography} from '@mui/material';
import {Fonts} from '@crema/constants/AppEnums';
import {MdOutlineMail} from 'react-icons/md';

const MyProfile = ({user}: any) => {
  const {name, uid, email, description} = user;

  return (
    <AppCard
      sxStyle={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 450,
        py: 0,
        px: 0,
      }}
      contentStyle={{py: 0, px: 4}}
      actionStyle={{p: 0, m: 0}}
      footerStyle={{p: 0, m: 0}}
      key={uid}
      title={'Admin Profile'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 1,
          height: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            sx={{
              width: {xs: 50, md: 80, lg: 95, xl: 105},
              height: {xs: 50, md: 80, lg: 95, xl: 105},
              my: 1,
            }}
            src={user?.profileImage ? user?.profileImage : null}
          >
            {user?.profileImage
              ? user?.profileImage
              : name?.charAt(0).toUpperCase()}
          </Avatar>
          <Box
            sx={{
              mb: 1,
              fontSize: 16,
              fontWeight: Fonts.BOLD,
            }}
          >
            {name}
          </Box>
          <Typography sx={{color: 'text.secondary'}}>
            {description ? description : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 1,
            p: {xs: 3, xl: 5},
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#E7F4FC',
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 1 / 2,
            }}
          >
            <Box sx={{mb: 1.5}}>
              <MdOutlineMail size={24} />
            </Box>
            <Typography
              sx={{
                my: 1,
                fontSize: {xs: 8, md: 10, lg: 12},
              }}
            >
              {email ? email : 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </AppCard>
  );
};

export default MyProfile;
