import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import AppLoader from '@crema/components/AppLoader';
import Home from '../../../modules/Home';

export const analyticsConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/Home',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Home></Home>
      </Suspense>
    ),
  },
];
