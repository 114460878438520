import React from 'react';
import {Box} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

type Props = {
  file: any;
  onDeleteUploadFile: (file: any) => void;
};

const PreviewThumb = ({file, onDeleteUploadFile}: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        mb: 8,
        mr: 8,
        width: 250,
        height: 250,
        p: 1,
        boxSizing: 'border-box',
        '& img': {
          display: 'block',
          width: 'auto',
          objectFit: 'cover',
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
        }}
      >
        <DeleteOutlineOutlinedIcon
          sx={{
            color: 'text.secondary',
            borderRadius: '50%',
            p: 1,
            '&:hover, &:focus': {
              color: 'warning.main',
              backgroundColor: 'primary.contrastText',
            },
          }}
          onClick={onDeleteUploadFile}
        />
      </Box>
      {Object.keys(file?.local).length > 0 && file?.local?.preview ? (
        <img alt='preview' src={file?.local?.preview} />
      ) : null}
    </Box>
  );
};

export default PreviewThumb;
