import React from 'react';
// import {RoutePermittedRole} from '@crema/constants/AppEnums';
import {TbDeviceDesktopAnalytics} from 'react-icons/tb';
import GroupIcon from '@mui/icons-material/Group';
import {BiSolidCategoryAlt} from 'react-icons/bi';
import {FaCodepen} from 'react-icons/fa';
import {ImBlog} from 'react-icons/im';

const routesConfig = [
  {
    id: 'user',
    title: 'Users',
    messageId: 'Home',
    type: 'item',
    // permittedRole: [RoutePermittedRole.Admin],
    icon: <TbDeviceDesktopAnalytics />,
    url: '/Home',
  },
  {
    id: 'manage',
    title: 'Manage',
    messageId: 'sidebar.manage',
    type: 'group',
    children: [
      {
        id: 'blog',
        title: 'Blog',
        messageId: 'Blogs',
        type: 'collapse',
        icon: 'rss_feed',
        // icon: <ImBlog />,
        // permittedRole: [RoutePermittedRole.Admin],
        children: [
          {
            id: 'createblog',
            title: 'createblog',
            messageId: 'Create Blog',
            type: 'item',
            // permittedRole: [RoutePermittedRole.Admin],
            url: '/createBlog',
          },
          {
            id: 'bloglisting',
            title: 'bloglisting',
            messageId: 'Blog Listing',
            type: 'item',
            // permittedRole: [RoutePermittedRole.Admin],
            url: '/bloglisting',
          },
          // {
          //   id: 'blogdetails',
          //   title: 'blogdetails',
          //   messageId: 'Blog Details',
          //   type: 'item',
          //   // permittedRole: [RoutePermittedRole.Admin],
          //   url: '/blogdetails',
          // },
        ],
      },
      {
        id: 'Portfolio',
        title: 'Portfolio',
        messageId: 'Portfolio',
        type: 'collapse',
        // permittedRole: [RoutePermittedRole.Admin],
        icon: <FaCodepen />,
        children: [
          {
            id: 'create',
            title: 'Create',
            messageId: 'Create',
            type: 'item',
            // permittedRole: [RoutePermittedRole.Admin],
            url: '/createPortfolio',
          },
          {
            id: 'portfolios',
            title: 'Portfolio List',
            messageId: 'Portfolios',
            type: 'item',
            // permittedRole: [RoutePermittedRole.Admin],
            url: '/portfolios',
          },
          {
            id: 'ourpartners',
            title: 'ourpartners',
            messageId: 'Our Partners',
            // messageId: 'Pending Courses',
            type: 'item',
            // permittedRole: [RoutePermittedRole.Admin],
            url: '/ourpartners',
          },
        ],
      },
      // {
      //   id: 'services',
      //   title: 'Services',
      //   messageId: 'Services',
      //   type: 'item',
      //   // permittedRole: [RoutePermittedRole.Admin],
      //   url: '/services',
      //   icon: <GroupIcon />,
      // },
      {
        id: 'categories',
        title: 'Categories',
        messageId: 'Categories',
        type: 'item',
        // permittedRole: [RoutePermittedRole.Admin],
        url: '/categories',
        icon: <BiSolidCategoryAlt />,
      },
    ],
  },
];
export default routesConfig;
