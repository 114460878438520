import React from 'react';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/helpers/IntlMessages';
import {Fonts} from '@crema/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import {object, string, ref} from 'yup';
import {useAppDispatch} from 'toolkit/hooks';
import jwtAxios from '@crema/services/auth/jwt-auth';
import AppInfoView from '@crema/components/AppInfoView';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
} from '@crema/types/actions/Common.action';

const validationSchema = object({
  oldPassword: string()
    .required('Old password must be provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(30)
    .matches(
      /^[a-zA-Z0-9]*$/,
      'Password can only contain letters and numbers.',
    ),
  newPassword: string()
    .required('New password must be provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(30)
    .matches(
      /^[a-zA-Z0-9]*$/,
      'Password can only contain letters and numbers.',
    ),
  confirmPassword: string()
    .oneOf([ref('newPassword'), undefined], 'Passwords must match')
    .max(30)
    .required('Confirm password must be provided.'),
});

const ChangePassword = () => {
  const dispatch = useAppDispatch();

  const handleChangePassword = async (data: any, resetForm: any) => {
    const updatePasswordBody = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    try {
      dispatch({type: FETCH_START});
      await jwtAxios.patch('user/changePassword', updatePasswordBody);
      dispatch({type: FETCH_SUCCESS});
      dispatch({
        type: SHOW_MESSAGE,
        payload: 'Password has been updated successfully',
      });
      resetForm();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.response.data.message});
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: 'Old password is incorrect',
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          maxWidth: 650,
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: Fonts.BOLD,
            mb: {xs: 3, lg: 5},
          }}
        >
          <IntlMessages id='common.changePassword' />
        </Typography>
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          enableReinitialize={false}
          isInitialValid={false}
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            handleChangePassword(data, resetForm);
            setSubmitting(false);
          }}
        >
          {({isSubmitting}) => (
            <ChangePasswordForm isSubmitting={isSubmitting} />
          )}
        </Formik>
      </Box>
      <AppInfoView />
    </>
  );
};

export default ChangePassword;
