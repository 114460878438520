/* eslint-disable prettier/prettier */
import {
  GET_CATEGORY_DATA,
  SET_PORTFOLIO_LISTING,
  SET_BLOG_LISTING,
} from '@crema/types/actions/Dashboard.action';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {Dispatch} from 'redux';
import {AppActions} from '@crema/types/actions';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const getCategoryList = (type: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/getAllCategories?type=${type}`)
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: GET_CATEGORY_DATA, payload: data.data.data});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getPortfoliosListing = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    await jwtAxios
      .get('projects/getAll?userType=admin')
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: SET_PORTFOLIO_LISTING, payload: data.data.data});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getBlogsListing = (input?: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`blog/getAll?data=all&searchTitle=${input}`)
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: SET_BLOG_LISTING, payload: data?.data?.blogs});
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const getALLCategoriesListing = (type: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/getAllCategories?type=${type}`)
      .then((res: any) => {
        dispatch(fetchSuccess());
        // dispatch({ type: GET_ALL_CATEGORIES, payload: res?.data?.data });
        dispatch({type: GET_CATEGORY_DATA, payload: res?.data?.data});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};
