import React from 'react';
import {
  alpha,
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
  styled,
} from '@mui/material';
import AppGridContainer from '@crema/components/AppGridContainer';
import IntlMessages from '@crema/helpers/IntlMessages';
import {useDropzone} from 'react-dropzone';
import {Form} from 'formik';
import AppTextField from '@crema/components/AppFormComponents/AppTextField';
import EditIcon from '@mui/icons-material/Edit';
import {Fonts} from '@crema/constants/AppEnums';
import jwtAxios from '@crema/services/auth/jwt-auth';

const AvatarViewWrapper = styled('div')(({theme}) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    '& .edit-icon': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: 26,
      height: 26,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    '&.dropzone': {
      outline: 0,
      '&:hover .edit-icon, &:focus .edit-icon': {
        display: 'flex',
      },
    },
  };
});

type PersonalInfoFormProps = {
  setFieldValue: (field: string, data: any) => void;
  values: any;
  isSubmitting: any;
  setMediaID: any;
  user: any;
};

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({
  values,
  setFieldValue,
  isSubmitting,
  setMediaID,
  user,
}) => {
  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: async (acceptedFiles) => {
      console.log(acceptedFiles);
      setFieldValue('profileImage', URL.createObjectURL(acceptedFiles[0]));
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      try {
        const res = await jwtAxios.post('media/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMediaID(res.data.data._id);
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  });

  return (
    <Form noValidate autoComplete='off'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: {xs: 5, lg: 6},
        }}
      >
        <AvatarViewWrapper {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <label htmlFor='icon-button-file'>
            {values?.profileImage ? (
              <Avatar
                sx={{
                  width: {xs: 50, lg: 60},
                  height: {xs: 50, lg: 60},
                  cursor: 'pointer',
                }}
                src={values?.profileImage}
              />
            ) : (
              <Avatar
                sx={{
                  width: {xs: 50, lg: 60},
                  height: {xs: 50, lg: 60},
                  fontSize: 24,
                  backgroundColor: '#339966',
                }}
              >
                {user?.name?.charAt(0).toUpperCase()}
              </Avatar>
            )}
            <Box className='edit-icon'>
              <EditIcon />
            </Box>
          </label>
        </AvatarViewWrapper>
        <Box sx={{ml: 4}}>
          <Typography
            sx={{
              fontWeight: Fonts.MEDIUM,
              fontSize: 16,
            }}
          >
            {values?.name}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: 14,
            }}
          >
            {values?.email}
          </Typography>
        </Box>
      </Box>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          <AppTextField name='name' fullWidth label='Full Name' />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField name='description' fullWidth label='Description' />
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            disabled={isSubmitting}
          >
            <IntlMessages id='common.saveChanges' />
          </Button>
        </Grid>
      </AppGridContainer>
    </Form>
  );
};

export default PersonalInfoForm;
