import React, {useState} from 'react';
import {Tabs, Tab, Box} from '@mui/material';
import {BiUser} from 'react-icons/bi';
import {AiOutlineLock} from 'react-icons/ai';
import AppAnimate from '@crema/components/AppAnimate';
import {Fonts} from '@crema/constants/AppEnums';
import {
  AccountTabsWrapper,
  ChangePassword,
  PersonalInfo,
} from '@crema/modules/account/MyProfile';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabs = [
  {id: 1, icon: <BiUser />, name: 'Personal Info'},
  {id: 2, icon: <AiOutlineLock />, name: 'Change Password'},
];

const Account = () => {
  const [value, setValue] = useState(0);

  const onTabsChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <AppAnimate animation='transition.slideDownIn' delay={60}>
        <Box
          component='h2'
          sx={{
            fontSize: 16,
            color: 'text.primary',
            fontWeight: Fonts.SEMI_BOLD,
            mb: {
              xs: 2,
              lg: 4,
            },
          }}
        >
          My Account
        </Box>
      </AppAnimate>
      <AccountTabsWrapper key='2'>
        <AppAnimate animation='transition.slideLeftIn' delay={120}>
          <Tabs
            className='account-tabs'
            value={value}
            onChange={onTabsChange}
            aria-label='basic tabs example'
            orientation='vertical'
          >
            {tabs.map((tab, index) => (
              <Tab
                className='account-tab'
                label={tab.name}
                icon={tab.icon}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppAnimate>
        <AppAnimate animation='transition.slideRightIn' delay={180}>
          <Box className='account-tabs-content'>
            {value === 0 && <PersonalInfo />}
            {value === 1 && <ChangePassword />}
          </Box>
        </AppAnimate>
      </AccountTabsWrapper>
    </>
  );
};

export default Account;
