import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {AuthUserType} from '@crema/types/models/AuthUser';
import jwtAxios, {setAuthToken} from './index';
import {useInfoViewActionsContext} from '@crema/context/AppContextProvider/InfoViewContextProvider';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'toolkit/hooks';
import {
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
} from '@crema/types/actions/Common.action';
import {SHOW_MODAL} from '@crema/constants/ActionTypes';
import {
  GET_CATEGORY_DATA,
  SET_BLOG_LISTING,
  SET_CAT_DATA,
  SET_PORTFOLIO_LISTING,
  SET_SINGLE_BLOG,
  SET_SINGLE_PORTFOLIO,
} from '@crema/types/actions/Dashboard.action';

interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const infoViewActionsContext = useInfoViewActionsContext();

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem('TokenTechverticksAdmin');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      try {
        const res = await jwtAxios.get('/user/getMyself');
        setJWTAuthData({
          user: res?.data?.data,
          isAuthenticated: true,
          isLoading: false,
        });
      } catch (error) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      const {data} = await jwtAxios.post('auth/user/signin', {
        email,
        password,
      });
      localStorage.setItem('TokenTechverticksAdmin', data?.token);
      setAuthToken(data?.token);
      const res = await jwtAxios.get('user/getMyself');
      setJWTAuthData({
        user: res?.data?.data,
        isAuthenticated: true,
        isLoading: false,
      });
      infoViewActionsContext.fetchSuccess();
      dispatch({type: SHOW_MESSAGE, payload: 'Successfully Login'});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError(error?.response?.data?.message);
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: error.response.data.message,
      });
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
    confirmPassword,
  }: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    infoViewActionsContext.fetchStart();
    try {
      await jwtAxios.post('auth/admin/signup', {
        name,
        email,
        password,
        confirmPassword,
      });
      infoViewActionsContext.fetchSuccess();
      navigate('/signin');
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError('Something went wrong');
    }
  };

  const logout = async () => {
    localStorage.removeItem('TokenTechverticksAdmin');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    dispatch({type: SHOW_MESSAGE, payload: 'Logged Out'});
    dispatch({type: GET_CATEGORY_DATA, payload: []});
    dispatch({type: SET_CAT_DATA, payload: null});
    dispatch({type: SHOW_MODAL, payload: false});
    dispatch({type: SET_PORTFOLIO_LISTING, payload: []});
    dispatch({type: SET_SINGLE_PORTFOLIO, payload: null});
    dispatch({type: SET_BLOG_LISTING, payload: []});
    dispatch({type: SET_SINGLE_BLOG, payload: null});
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
