/* eslint-disable prettier/prettier */
import {
  GET_CATEGORY_DATA,
  SET_CAT_DATA,
  GET_ALL_CATEGORIES,
  SET_PORTFOLIO_LISTING,
  SET_BLOG_LISTING,
  SET_SINGLE_BLOG,
  SET_SINGLE_PORTFOLIO,
} from '@crema/types/actions/Dashboard.action';
import { createAction } from '@reduxjs/toolkit';
import { CategoryData } from '@crema/types/models/dashboards/CategoryData';

// all categories actions
export const GetCategoryList = createAction<any>(GET_CATEGORY_DATA);
export const GetCategoryData = createAction<CategoryData>(SET_CAT_DATA);
export const GetAllCategories = createAction<any>(GET_ALL_CATEGORIES);

// blog actions
export const setBlogListing = createAction<any>(SET_BLOG_LISTING);
export const setSingleBlog = createAction<any>(SET_SINGLE_BLOG);

// portfolio redux actions
export const SetPortfolioListing = createAction<any>(SET_PORTFOLIO_LISTING);
export const setSingleProject = createAction<any>(SET_SINGLE_PORTFOLIO);