import React from 'react';
import AppAnimate from '@crema/components/AppAnimate';
import AppGridContainer from '@crema/components/AppGridContainer';
import {Grid, Box} from '@mui/material';
import {MyProfile} from '@crema/modules/dashboards/Academy';
import {useAuthUser} from '@crema/hooks/AuthHooks';
import AppInfoView from '@crema/components/AppInfoView';

const Home = () => {
  const {user} = useAuthUser();

  return (
    <>
      <AppAnimate animation='transition.slideUpIn' delay={160}>
        <Box
          sx={{
            pb: {xs: 5, md: 8},
          }}
        >
          <AppGridContainer>
            <Grid item xs={12} sm={4} lg={4}>
              <MyProfile user={user} />
            </Grid>
          </AppGridContainer>
        </Box>
      </AppAnimate>
      <AppInfoView />
    </>
  );
};

export default Home;
