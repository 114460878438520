/* eslint-disable prettier/prettier */
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA';
export const SET_CAT_DATA = 'SET_CAT_DATA';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const SET_PORTFOLIO_LISTING = 'SET_PORTFOLIO_LISTING';
export const SET_BLOG_LISTING = 'SET_BLOG_LISTING';
export const SET_SINGLE_BLOG = 'SET_SINGLE_BLOG';
export const SET_SINGLE_PORTFOLIO = 'SET_SINGLE_PORTFOLIO';

export type getAllCategories = {
  type: typeof GET_ALL_CATEGORIES;
  payload: any;
};

export type GetCategoryList = {
  type: typeof GET_CATEGORY_DATA;
  payload: any;
};

export type GetCategoryData = {
  type: typeof SET_CAT_DATA;
  payload: any;
};

export type setportfoliolisting = {
  type: typeof SET_PORTFOLIO_LISTING;
  payload: any;
};

export type setblogslisting = {
  type: typeof SET_BLOG_LISTING;
  payload: any;
};

export type setsingleblogdata = {
  type: typeof SET_SINGLE_BLOG;
  payload: any;
};

export type setsingleportfolio = {
  type: typeof SET_SINGLE_PORTFOLIO;
  payload: any | object;
};

export type DashboardActionTypes =
  | getAllCategories
  | GetCategoryList
  | GetCategoryData
  | setportfoliolisting
  | setblogslisting
  | setsingleblogdata
  | setsingleportfolio;
