import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';
import AppLoader from '@crema/components/AppLoader';

export const accountPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/my-account',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Account />
      </Suspense>
    ),
  },
];
